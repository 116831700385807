<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<template v-slot:divider>
				<v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>
		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
		<v-toolbar>
			<v-container fluid>
				<v-row dense>
					<v-col cols="4">
						<v-select :items="filterPeriodItems" v-model="filterSelectedPeriod" @change="LoadStats">
						</v-select>
					</v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'">
						<DatePicker label="Data Inizio" v-model="filterCustomStartDate" @input="LoadStats"></DatePicker>
					</v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'">
						<DatePicker label="Data Fine" v-model="filterCustomEndDate" @input="LoadStats"></DatePicker>
					</v-col>
				</v-row>
			</v-container>
			<v-btn color="primary" @click="downloadCsv"><v-icon left>fas fa-download</v-icon>{{$t("common.download")}} CSV</v-btn>
		</v-toolbar>
		<v-progress-linear indeterminate v-if="LoadingData"></v-progress-linear>
		<v-card class="elevate-5" v-if="PeriodStats.length">
			<v-simple-table>
				<thead>
				<tr>
					<th v-for="(value, key) in PeriodStats[0]" :key="key">
						{{ key }}
					</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(stat, index) in PeriodStats" :key="index">
					<td v-for="(value, key) in stat" :key="key">
						{{ value }}
					</td>
				</tr>
				</tbody>
			</v-simple-table>
		</v-card>

	</div>
</template>
<script>
import DatePicker from "@/components/Shared/FormControl/DatePicker.vue";
import CrudClient from "@/services/CrudClient";
import moment from "moment/moment";
import { mapActions } from "vuex";

export default {
	name: "NutritionistConversionRates",

	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{
				name: "description",
				content: this.$t("pageTitle")
			}],
		};
	},

	components: { DatePicker },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Dashboard",
					disabled: false,
					exact: true,
					to: "/Admin/Dashboard",
				}
			],
			LoadingData: false,
			PeriodStats: [],
			filterSelectedPeriod: null,
			filterPeriodItems: [
				{ divider: true },
				{
					text: "Personalizzato",
					value: "CUSTOM"
				},
			],
			filterCustomStartDate: null,
			filterCustomEndDate: null
		};
	},
	created() {
		this.ReportsService = new CrudClient("Reports/PeriodStats");
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Admin/Dashboard/MonthlySummary",
		},);

		// enumerate months from 06/2023 to today in descending order

		const today = moment();
		const currentMonth = moment("2023-03");

		while (currentMonth.isBefore(today)) {
			this.filterPeriodItems.unshift({
				text: currentMonth.format("MMMM YYYY"),
				value: currentMonth.format("YYYY/MM")
			});
			currentMonth.add(1, "months");
		}

		this.filterSelectedPeriod = "CUSTOM";
		this.filterCustomStartDate = "2024-9-1";

		this.filterCustomEndDate = moment().add(-15, "days").format("YYYY-MM-DD");

		await this.LoadStats();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getIntervalDates() {
			if (!this.filterSelectedPeriod) {
				return null;
			}

			if (this.filterSelectedPeriod === "CUSTOM") {
				if (this.filterCustomStartDate && this.filterCustomEndDate) {
					return {
						startDate: this.filterCustomStartDate,
						endDate: this.filterCustomEndDate
					}
				}

				return null;
			}
			const period = this.filterSelectedPeriod.split("/");

			const year = parseInt(period[0]);
			const month = parseInt(period[1]);

			let endYear = year;
			let endMonth = month + 1;
			if (endMonth > 12) {
				endMonth = 1;
				endYear++;
			}

			return {
				startDate: period[0] + "-" + period[1] + "-1",
				endDate: endYear + "-" + endMonth + "-1",
			}
		},

		isFloat(n) {
			return typeof n === "number" && Number.isFinite(n) && !Number.isInteger(n);
		},

		async LoadStats() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) {
					return;
				}

				this.$log.debug(interval);
				this.PeriodStats = await this.ReportsService.Get(`DailyPayments?startDate=${interval.startDate}&endDate=${interval.endDate}`);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async downloadCsv() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) { return; }

				const csvData = await this.ReportsService.Get(`DailyPaymentsCsv?startDate=${interval.startDate}&endDate=${interval.endDate}`);
				this.$log.debug(csvData);
				const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
				const link = document.createElement("a");
				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", `report_${interval.startDate}_to_${interval.endDate}.csv`);
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

	}

}
</script>
<i18n>
{
	"it": {
		"pageTitle": "Pagamenti"
	}
}
</i18n>
