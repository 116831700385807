<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
			<template v-slot:divider>
				<v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>

		<v-card class="elevate-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("nutriyou.dashboard") }}</v-toolbar-title>
			</v-toolbar>

			<v-row class="mx-1 py-3">
				<v-col cols="12" sm="6" md="4" class="py-1 static-navigation" v-for="item in staticNavigation" :key="item.text">
					<v-card dark color="secondary" class="pa-1 elevateOnHover" :to="{ path: item.path }" append>
						<v-card-text>
							<v-icon class="mr-2" v-if="item.icon">{{ item.icon }}</v-icon>
							<span class="ItemText white--text">{{ item.text }}</span>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>
<script>
export default {
	metaInfo() {
		return {
			title: this.$t("nutriyou.dashboard"),
			meta: [{
				name: "description",
				content: this.$t("pageTitle")
			}],
		};
	},

	components: {},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Dashboard",
					disabled: true,
					exact: true,
					to: "/Admin/Dashboard",
				},
			],
			staticNavigation: [
				{
					path: "./MonthlySummary",
					text: "Monthly summary",
					icon: "fas fa-users",
				},
				{
					path: "./ConversionTimeline",
					text: "Timeline",
					icon: "fas fa-users",
				},
				{
					path: "./Pivot",
					text: "Pivot",
					icon: "fas fa-table",
				},
				{
					path: "./NutritionistConversionRates",
					text: "Conversione Nutrizionisti",
					icon: "fas fa-table",
				}, {
					path: "./NutritionistPatientTimeline",
					text: "Nutritionista Timeline",
					icon: "fas fa-table",
				},
				{
					path: "./DailyPayments",
					text: "DailyPayments",
					icon: "fas fa-table",
				}
			],
		};
	}
};
</script>
